<template>
  <template v-if="!loading">
    <div
      class="col-12 mb-3 fade-in"
      v-for="spectacle in spectacles"
      :key="spectacle"
    >
      <div class="spectacle-list-v2 grid">
        <div class="image-container col-md-6 col-sm-12">
          <img
            class="image-content"
            :src="'data:image/jpeg;base64,' + spectacle.picture.content"
          />
        </div>
        <div class="col-md-4 col-sm-12 spectacle-details">
          <div class="grid">
            <div class="col-12 name">
              <b>{{ spectacle.name }}</b>
            </div>
            <div class="col-12 location">
              <span class="city">{{ spectacle.salle.city }}</span> -
              <span class="name">{{ spectacle.salle.name }}</span>
            </div>
            <div class="col-12 date">
              <span>Date :</span> {{ spectacle.datePeriod }}
            </div>
            <div class="col-12 description-wrapper">
              <div class="description" v-html="spectacle.description"></div>
            </div>
          </div>
        </div>
        <div class="col-md-2 col-sm-12 text-align-center d-block">
          <div class="col-12"></div>
          <p v-if="spectacle.salle.isHandicapped">
            <img
              :src="'../../images/pmr.png'"
              style="height: 20px"
              class="mr-1"
            />
            Accès PMR
          </p>
          <Button
            label="Plus d'infos et inscriptions"
            class="jm-red"
            :href="spectacle.webLink"
            @click="openSpectacleDialog(spectacle)"
          />
        </div>
      </div>
    </div>

    <Dialog
      v-model:visible="showSpectacleDialog"
      :style="{ width: '500px' }"
      header="Informations"
      :modal="true"
    >
      <div class="col-12">
        <div class="p-fluid grid">
          <div class="col-12">
            <b
              >{{
                getModuleAccess("reservations")
                  ? "Choisissez date et horaire"
                  : "Liste des dates et horaires"
              }}
            </b>
          </div>
          <template v-for="date in spectacle.spectacleDates" v-bind:key="date">
            <div v-if="isDateAfterToday(date.dateSpectacle)" class="col-6">
              <a
                :href="
                  getModuleAccess('reservations')
                    ? goToRegisterPage(
                        spectacle.spectacleId,
                        date.dateSpectacleId
                      )
                    : undefined
                "
                target="_blank"
              >
                <Button class="p-button jm-white">
                  {{ formatDateTime(date.dateSpectacle) }}
                </Button>
              </a>
            </div>
          </template>
          <div class="col-12">
            <b>Description</b>
          </div>
          <div class="col-12" v-html="spectacle.description"></div>
        </div>
      </div>
      <div class="p-dialog-footer">
        <a :href="spectacle.webLink" target="_blank">
          <Button
            label="Plus d'infos"
            class="mr-2 jm-red"
            icon="pi pi-plus"
            :href="spectacle.webLink"
          />
        </a>
        <Button
          label="Fermer"
          icon="pi pi-times"
          class="p-button-text jm-red-color"
          @click="showSpectacleDialog = false"
        />
      </div>
    </Dialog>
  </template>
  <template v-else>
    <div
      class="loader-container d-flex justify-content-center align-items-center"
    >
      <div class="loader"></div>
    </div>
  </template>
</template>
<script>
import SpectacleService from "@/service/SpectacleService";
import randomRef from "@mixins/randomRef";
import "mdb-vue-ui-kit/css/mdb.min.css";
import { formatDateTime } from "/src/utils/utils.js";
import { getModuleAccess } from "../../utils/utils";

export default {
  name: "SpectacleList",

  components: {},

  mixins: [randomRef],

  data() {
    return {
      loading: false,
      spectacles: [],
      showSpectacleDialog: false,
      spectacle: {},
    };
  },

  spectacleService: null,

  created() {
    this.spectacleService = new SpectacleService();
  },

  mounted() {
    this.loading = true;
    this.spectacleService.comingSpectacles().then((data) => {
      this.spectacles = data;
      this.initSpectacleDatesDaysPeriod(this.spectacles);
      this.loading = false;
    });
  },

  methods: {
    openSpectacleDialog(spectacle) {
      this.spectacle = { ...spectacle };
      this.showSpectacleDialog = true;
    },

    isDateAfterToday(date) {
      const today = new Date();
      const spectacleDate = new Date(date);

      return spectacleDate > today;
    },
    formatDateTime,
    goToRegisterPage(specId, dateId) {
      return "/public/spectacle/" + specId + "/register/" + dateId;
      //route.push({name: "spectacleRegister", params: { spectacleId: specId }});
    },
    initSpectacleDatesDaysPeriod(spectacles) {
      for (let i = 0; i < spectacles.length; i++) {
        const dates = spectacles[i].spectacleDates;

        if (dates.length === 1) {
          const date = new Date(dates[0].dateSpectacle);
          const day = date.getDate();
          const month = date.toLocaleString("default", { month: "long" });
          //get year
          const year = date.getFullYear();
          spectacles[i].datePeriod = `${day} ${month} ${year}`;
        } else {
          dates.sort(
            (a, b) => new Date(a.dateSpectacle) - new Date(b.dateSpectacle)
          );

          const startDate = new Date(dates[0].dateSpectacle);
          const startDay = startDate.getDate();
          const startMonth = startDate.toLocaleString("default", {
            month: "long",
          });
          const startYear = startDate.getFullYear();

          const endDate = new Date(dates[dates.length - 1].dateSpectacle);
          const endDay = endDate.getDate();
          const endMonth = endDate.toLocaleString("default", { month: "long" });
          const endYear = endDate.getFullYear();

          if (startDay === endDay && startMonth === endMonth) {
            spectacles[i].datePeriod = `${startDay} ${startMonth} ${startYear}`;
          } else if (startYear !== endYear) {
            spectacles[
              i
            ].datePeriod = `Du ${startDay} ${startMonth} ${startYear} au ${endDay} ${endMonth} ${endYear}`;
          } else {
            spectacles[
              i
            ].datePeriod = `Du ${startDay} ${startMonth} au ${endDay} ${endMonth}`;
          }
        }
      }
      return spectacles;
    },
    getModuleAccess,
  },
};
</script>

<style lang="scss">
.header-spectacles-list {
  padding-left: 40px;
}

.content {
  padding: 40px 15% 40px 15%;
}

//content but with less padding on mobile
@media (max-width: 768px) {
  .content {
    padding: 40px 5% 40px 5%;
  }
  .name {
    margin-bottom: 0px !important; /* Réduction de la marge pour le nom */
  }

  .city {
    margin-bottom: 0p !important; /* Réduction de la marge pour la ville */
  }
  .location {
    margin-bottom: 0px !important; /* Réduction de la marge pour le lieu */
  }
}

.image-content {
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-height: 240px;
  border-radius: 10px;
}

.image-container {
  padding: 0;
}

.grid {
  margin: 0;
}

/* HTML: <div class="loader"></div> */
.loader {
  width: 50px;
  aspect-ratio: 1;
  display: grid;
  border: 4px solid #0000;
  border-radius: 50%;
  border-color: #ccc #0000;
  animation: l16 1s infinite linear;
}

.loader::before,
.loader::after {
  content: "";
  grid-area: 1/1;
  margin: 2px;
  border: inherit;
  border-radius: 50%;
}

.loader::before {
  border-color: #f03355 #0000;
  animation: inherit;
  animation-duration: 0.5s;
  animation-direction: reverse;
}

.loader::after {
  margin: 8px;
}

@keyframes l16 {
  100% {
    transform: rotate(1turn);
  }
}

.fade-in {
  animation: fadeInAnimation 0.3s ease-in;
}

@keyframes fadeInAnimation {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.loader-container {
  height: 50vh;
  /* Si vous voulez centrer verticalement */
}
.description-wrapper {
  position: relative;
  overflow: hidden;
}

.description {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  /* Vous pouvez ajuster la taille de la police, la hauteur de ligne, etc. selon vos besoins */
}

.spectacle-details {
  font-family: Arial, sans-serif;
  color: #333;
}

.name {
  font-size: 1.2em;
  margin-bottom: 10px;
}

.location {
  font-size: 1em;
  margin-bottom: 5px;
}

.city {
  font-size: 1.2em;
  margin-bottom: 10px;
}

.date {
  font-size: 1em;
  margin-bottom: 5px;
}

.description-wrapper {
  margin-top: 10px;
}

.description {
  font-size: 0.9em;
  line-height: 1.4;
}
</style>
